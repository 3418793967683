<template>
  <div style="position: relative">
    <Breadcrumb :Breadcrumb="BreadcrumbCon" />
    <div class="search">
      <el-select
        v-model="valueArea"
        filterable
        clearable
        placeholder="请选择区号(可搜索关键字)"
        style="width: 240px"
        v-if="internationType"
      >
        <el-option
          v-for="item in options"
          :key="item.name"
          :label="item.name + item.tel"
          :value="item.tel"
        >
        </el-option>
      </el-select>
      <el-input placeholder="请输入手机号" v-model="phone"></el-input>
      <el-select v-model="grade" placeholder="请选择用户等级">
        <el-option label="无" value="0"></el-option>
        <el-option label="A" value="1"></el-option>
        <el-option label="B" value="2"></el-option>
        <el-option label="C" value="3"></el-option>
        <el-option label="D" value="4"></el-option>
      </el-select>

      <div>
        <el-button type="primary" @click="searchList">搜索</el-button>
        <el-button type="info" @click="reset">重置</el-button>
      </div>
    </div>

    <div class="table_con">
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        height="100%"
        :header-cell-style="{ 'text-align': 'center' }"
        :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column type="index" label="序号" width="80">
          <template slot-scope="scope">
            <span>{{ scope.$index + (currentPage - 1) * pageSize + 1 }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="userName" label="姓名"> </el-table-column>
        <el-table-column prop="phoneArea" label="区号"> </el-table-column>
        <el-table-column prop="phone" label="电话"> </el-table-column>
        <el-table-column prop="grade" label="用户等级"> </el-table-column>
        <el-table-column prop="pledge" label="质押数量"> </el-table-column>
        <el-table-column prop="txid" label="txid" width="200">
          <template slot-scope="{ row }">
            <div style="display: flex; align-items: center">
              <p class="pledge_detail_txid" style="margin: 0">
                {{ row.txid }}
              </p>
              <span style="cursor: pointer">
                <i
                  class="el-icon-copy-document"
                  v-if="row.txid != null"
                  v-clipboard:copy="row.txid"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                ></i>
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="{ row }">
            <el-button @click="handleDetail(row)" type="text" size="small"
              >查看详情</el-button
            >

            <el-dialog
              title="用户详情"
              :visible.sync="dialogVisible"
              width="50%"
              :append-to-body="true"
            >
              <div class="pledge_detail">
                <h3>基本信息</h3>
                <div class="pledge_detail_p">
                  <p><span>姓名：</span>{{ pledgeRow.userName }}</p>
                  <p>
                    <span>电话号码：</span
                    >{{ pledgeRow.phoneArea + " " + pledgeRow.phone }}
                  </p>
                  <p><span>用户等级：</span>{{ pledgeRow.grade }}</p>
                  <p><span>质押数量：</span>{{ pledgeRow.pledge }}</p>
                  <p><span>状态：</span>{{ pledgeRow.pledgeStatus }}</p>
                </div>
              </div>
              <div class="pledge_detail">
                <h3>质押记录</h3>
                <el-table
                  :data="tableDataPledge"
                  border
                  style="width: 100%"
                  :header-cell-style="{ 'text-align': 'center' }"
                  :cell-style="{ 'text-align': 'center' }"
                >
                  <el-table-column type="index" label="序号" width="80">
                    <template slot-scope="scope">
                      <span>{{
                        scope.$index + (currentPage - 1) * pageSize + 1
                      }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="pledgeTime" label="质押时间">
                  </el-table-column>
                  <el-table-column prop="状态" label="状态">
                    <template slot-scope="{ row }">
                      {{ row.auditor == "auto" ? "自动" : "手动"
                      }}{{ row.operationType == 1 ? "质押" : "解押"
                      }}{{ row.cple }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="txid" label="txid" width="200">
                    <template slot-scope="{ row }">
                      <div style="display: flex; align-items: center">
                        <p class="pledge_detail_txid" style="margin: 0">
                          {{ row.txid }}
                        </p>
                        <span style="cursor: pointer">
                          <i
                            class="el-icon-copy-document"
                            v-if="row.txid != null"
                            v-clipboard:copy="row.txid"
                            v-clipboard:success="onCopy"
                            v-clipboard:error="onError"
                          ></i>
                        </span>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-dialog>
          </template>
        </el-table-column>
      </el-table>

      <Pagination
        v-show="pageLength >= 0"
        :total="pageLength"
        :limit.sync="pageSize"
        @pagination="handlePageChange"
        :page-sizes="pageSizes"
      />
    </div>
  </div>
</template>

<script>
import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";
import { hideLoading, showLoading } from "@/util/loading";
import { areaCodeData } from "@/util/areaCodeData";

import Breadcrumb from "@/components/BreadCrumb";
import Pagination from "@/components/Pagination";

export default {
  components: { Breadcrumb, Pagination },
  inject: ["reload"],

  data() {
    return {
      BreadcrumbCon: [
        {
          name: "行世界",
        },
        {
          name: "用户质押管理",
        },
        {
          name: "未锁仓",
        },
      ], // 面包屑数据

      phone: "",
      grade: "",

      options: [],
      valueArea: "",
      dialogVisible: false,
      tableDataPledge: [],
      pledgeRow: {},

      tableData: [],
      dialogFormVisible: false,

      internationType: false,
      role: "",
      phoneAreaROLE: "",

      // 分页数据
      currentPage: 1, // 当前页
      pageLength: 0,
      pageSize: 10, //要传过去的数据 每页多少条数据
      pageSizes: [10, 20, 50, 100],
    };
  },

  mounted() {
    this.options = areaCodeData;
    this.role = localStorage.getItem("ROLE");

    var strNation = this.role.includes("ROLE_nation");
    if (strNation) {
      this.internationType = true;
    }
    this.phoneAreaROLE = localStorage.getItem("phoneArea");

    showLoading();
    const opt = {
      url: reqUrl.worldTravelQueryWorldTravelPledgeList,
      method: "POST",
      params: JSON.stringify({
        phone: "",
        phoneArea: this.phoneAreaROLE,
        grade: "",
        pledgeStatus: 1,
      }),
      resFunc: (res) => {
        console.log(res.data);
        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        } else if (res.data.code == 0) {
          this.tableData = res.data.data.list;
          this.pageLength = res.data.data.total;
          this.pageSize = res.data.data.pageSize;

          this.tableData.map((item) => {
            this.changeItem(item);
          });
          hideLoading();
        }
        hideLoading();
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        hideLoading();
      },
    };
    request(opt);
  },

  methods: {
    searchList() {
      showLoading();
      const opt = {
        url: reqUrl.worldTravelQueryWorldTravelPledgeList,
        method: "POST",
        params: JSON.stringify({
          phone: this.phone,
          phoneArea: this.valueArea || this.phoneAreaROLE,
          grade: this.grade,
          pledgeStatus: 1,
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          } else if (res.data.code == 0) {
            this.tableData = res.data.data.list;
            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;

            this.tableData.map((item) => {
              this.changeItem(item);
            });
            hideLoading();
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          hideLoading();
        },
      };
      request(opt);
    },

    handlePageChange(data) {
      showLoading();
      const opt = {
        url: reqUrl.worldTravelQueryWorldTravelPledgeList,
        method: "POST",
        params: JSON.stringify({
          phone: this.phone,
          phoneArea: this.valueArea || this.phoneAreaROLE,
          grade: this.grade,
          pledgeStatus: 1,
          pageNum: data.pageIndex,
          pageSize: data.pageSize,
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          } else if (res.data.code == 0) {
            this.tableData = res.data.data.list;
            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;
            this.currentPage = data.pageIndex;

            this.tableData.map((item) => {
              this.changeItem(item);
            });
            hideLoading();
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          hideLoading();
        },
      };
      request(opt);
    },

    handleDetail(row) {
      this.dialogVisible = true;
      this.pledgeRow = row;

      showLoading();
      const opt = {
        url: reqUrl.worldTravelQueryPledgeLog,
        method: "POST",
        params: JSON.stringify({
          userId: row.userId,
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          } else if (res.data.code == 0) {
            this.tableDataPledge = res.data.data;
            hideLoading();
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          hideLoading();
        },
      };
      request(opt);
    },

    changeItem(item) {
      if (item.pledgeStatus == 1) {
        item.pledgeStatus = "未锁仓";
      }

      if (item.grade == 0) {
        item.grade = "无";
      } else if (item.grade == 1) {
        item.grade = "A";
      } else if (item.grade == 2) {
        item.grade = "B";
      } else if (item.grade == 3) {
        item.grade = "C";
      } else if (item.grade == 4) {
        item.grade = "D";
      }
    },

    onCopy() {
      this.$message.success("复制成功");
    },
    onError() {
      this.$message.error("复制失败");
    },

    reset() {
      this.refresh();
    },

    // 刷新
    refresh() {
      this.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
